// Global Variables
var search = document.getElementById("search");
var searchBox = document.getElementById("searchbox");
var searchIcon = document.querySelector(".search--icon");
var closeIcon = document.querySelector(".close--icon");

var mobileMenu = document.getElementById("mobile-menu");
var openMenu = document.getElementById("open-mobile-menu");
var closeMenu = document.getElementById("close-mobile-menu");

var openSubMenu = document.querySelectorAll(".open-sub");
var closeSubMenu = document.querySelectorAll(".close-sub");


// ________________________________________________________ //


// Handle Opening the site search box
if ( null !== search ) {
  search.onclick = function () {
    showSearch = !showSearch
    if (showSearch === true) {
      searchBox.classList.add("active")
      searchIcon.style.display = "none"
      closeIcon.style.display = "flex"
  
      document.querySelector("#searchbox > input").focus();
    } else {
      searchBox.classList.remove("active")
      searchIcon.style.display = "flex"
      closeIcon.style.display = "none"
  
      document.querySelector("#searchbox > input").blur()
    }
  }
  
  let showSearch = false
}


// ________________________________________________________ //


// Handle opening/ closing the mobile menu
openMenu.onclick = function () {
  mobileMenu.style.display = "block"
  document.body.style.overflow = "hidden"
}

closeMenu.onclick = function () {
  mobileMenu.style.display = "none"
  document.body.style.overflow = "auto"
}

for (var i = 0; i < openSubMenu.length; i++) {
  openSubMenu[i].onclick = function () {
    let openIcon = this;
    let closeIcon = this.parentNode.children[1];
    let parent = this.parentNode.parentNode.parentNode;

    for (var i = 0; i < parent.children.length; i++) {
      if (parent.children[i].classList.contains("sub-menu")) {
        parent.children[i].style.display = "block";
        closeIcon.style.display = "block";
        openIcon.style.display = "none";
      }
    }
  }
}

for (var i = 0; i < closeSubMenu.length; i++) {
  closeSubMenu[i].onclick = function () {
    let closeIcon = this;
    let openIcon = this.parentNode.children[0];
    let parent = this.parentNode.parentNode.parentNode;

    for (var i = 0; i < parent.children.length; i++) {
      if (parent.children[i].classList.contains("sub-menu")) {
        parent.children[i].style.display = "none";
        closeIcon.style.display = "none";
        openIcon.style.display = "block";
      }
    }
  }
}
